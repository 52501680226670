import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import useLocalStorage from '@illinois/react-use-local-storage'

import { renderAst } from '../markdown'
import { ChapterContext } from '../context'
import CourseContentList from '../components/course-content-list'
import { Button } from '../components/button'
import { TaskItem } from '../components/task-item'

import classes from '../styles/task.module.sass'
import Wrapper from '../components/wrapper'

import { msalConfig, loginRequest } from './../utility/auth/msalConfig';
import { MsalProvider } from '../utility/auth/msalContext';

function waitForGlobal(name, timeout = 300) {
    return new Promise((resolve, reject) => {
      let waited = 0

      function wait(interval) {
        setTimeout(() => {
          waited += interval
          // some logic to check if script is loaded
          // usually it something global in window object
          if (window[name] !== undefined) {
            return resolve()
          }
          if (waited >= timeout * 1000) {
            return reject({ message: 'Timeout' })
          }
          wait(interval * 2)
        }, interval)
      }

      wait(2) //30
    })
  }

const Chapter = (props) => {

    const {data} = props;
    const { markdownRemark, site } = data
    const { courseId } = site.siteMetadata
    const { frontmatter, htmlAst } = markdownRemark
    const { title, description, prev, next, id } = frontmatter
    const [activeExc, setActiveExc] = useState(null)


    const [completed, setCompleted] = useLocalStorage(`${courseId}-completed-${id}`, [])
    const html = renderAst(htmlAst)
    const buttons = [
        { slug: prev ? '/content/' + prev : prev, text: '« Föregående kapitel' },
        { slug: next ? '/content/' + next : next, text: 'Nästa kapitel »' },
    ]

    //const [loaded,setLoaded] = useState(false);

    useEffect(() => {
        waitForGlobal('MathJax').then(() => {
            window.MathJax.Hub.Config({
              tex2jax: {
                inlineMath: [['$', '$'], ['\\(', '\\)']],
                displayMath: [['$$', '$$']],
                processEscapes: true,
                processEnvironments: true,
                skipTags: ['script', 'noscript', 'style', 'textarea', 'pre'],
                TeX: {
                  equationNumbers: { autoNumber: 'AMS' },
                  extensions: ['AMSmath.js', 'AMSsymbols.js'],
                },
              },
            })
    //        setLoaded(true);
          })
          if (window.MathJax != null) {
            //var math = document.querySelector("."+classes.adaptiveGrid);
            //window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, math]);
            window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])
            //window.MathJax.typeset();
          }

          //return () => {window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])}
      });

    const chapterTasks = props.pageContext.tasks.map( (task, i) => {
        const taskExercises = task.node.htmlAst.children.filter((elem) => elem.tagName === "exercise")
        return <TaskItem chapterId={id} taskId={task.node.frontmatter.id} exercises={taskExercises} url={task.node.fields.slug} keyValue={i} description={task.node.frontmatter.description} title={task.node.frontmatter.title} index={task.node.frontmatter.index}> </TaskItem>
    });

    chapterTasks.sort(function(a, b) {
      if (a.props.index === null || b.props.index === null)
            return true;

        return parseInt(a.props.index) - parseInt(b.props.index);
      });

      /**
       * <Button variant="secondary" small onClick={() => navigate(slug)}>
                                            {text}
                                        </Button>
       */


    return (
        <ChapterContext.Provider value={{ activeExc, setActiveExc, completed, setCompleted}}>

            <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >


            <Wrapper>
              <div className={classes.navi}>
                <p className={classes.navilink} onClick={() => navigate('/course-material')}>Kursmaterial </p><p>/</p>
              </div>
              <CourseContentList id={id}title={title} description={description} tasks={props.pageContext.tasks}>
                {html}
                {chapterTasks}

                <section className={classes.pagination}>
                  {buttons.map(({ slug, text }, index) => (

                      <div key={index}>
                          { slug &&
                              (<Button small onClick={() => navigate(slug)}>
                                  {text}
                              </Button>)
                          }
                      </div>
                  ))}
                </section>
              </CourseContentList>
            </Wrapper>

            </MsalProvider>
    </ChapterContext.Provider>
    )
}

export default Chapter

export const pageQuery = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
                courseId
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            htmlAst
            frontmatter {
                id
                title
                description
                location
                prev
                next
                index
            }
        }
    }
`
