import React from 'react'

import classes from '../styles/task-item.module.sass'
import { Link } from "gatsby"
import { useMsal } from '../utility/auth/msalContext';

import classNames from 'classnames'

import {useState, useEffect}  from 'react'

export const TaskItem = ({type, title, url, chapterId, taskId, exercises, index}) => {


  const {progress} = useMsal()
  const [doneCount, setDoneCount] = useState(0)


  useEffect(() => {
      if(!progress) {
          console.debug("Failed to retrieve progress")
          return
      }
      const chapterFound = progress.modules.find(element => element.moduleId === chapterId)


      if (chapterFound) {
        
        const taskFound = chapterFound.sections.find(element => element.sectionId === taskId)

        if (taskFound) {

          let count = 0

          // Filters id:s client side which shouldn't exist in database but does so for some reason. For example if the id for an exercise has changed.
          // A better solution would be to just wipe those posts from the database.

          const filteredExercises = taskFound.exercises.filter((elem1 => exercises.find(elem2 => elem2.properties.id === elem1.exerciseId) !== undefined))

          filteredExercises.forEach((e) => {
            if (JSON.parse(e.exerciseData).status === "complete")
              count++
          })

          setDoneCount(count)
        }
      }
    // Callback only on progress update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    return (
      <Link to={url}>
        <div className={classes.item}>
            <div className={classes.header}>
              <div className={classes.leftheader}>
                { type !== "chapter" && <p className={classNames(classes.itemNr, { [classes.idCompleted]: doneCount === exercises.length })}>{index}</p>}
                <h3 className={classes.title}>{title}</h3>
              </div>
              { type != "chapter" && (exercises.length > 0) && <div className={classes.rightheader}>
                <p className={classes.progress}>Avklarat: {doneCount}/{exercises.length}</p>
              </div>}
            </div>
        </div>
      </Link>
    )
}
